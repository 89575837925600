import * as R from "ramda";
import type {
  ColumnValuesValidation,
  ValidationResults,
} from "common/form/types/validation";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { defaultFor } from "common";
import { deepEqual } from "common/component";
import { behaveAs, findColumn, getColumn } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { isConditionMonitoringFormValid } from "common/form/behavior-forms/condition-monitoring/functions";
import { FormValidation, GroupColumn, Layout } from "common/form/types";
import { merge3 } from "common/merge";
import { KeysOf, Properties } from "common/types/records";
import {
  column,
  columnWithDynamicValue,
  columnWithValue,
  isRecordSelfReference,
  skipColumnValidation,
} from "common/validate";

const COLUMNS_TO_SKIP = ["sites"];

const areColumnValuesValid = (props: Properties, entity: Entity) =>
  !entity?.columns.some(
    (c) => columnWithValue(entity, c, props?.[c.name]).length,
  );

const areColumnWithDynamicValuesValid = (
  entities: Entities,
  props: Properties,
  entity: Entity,
) =>
  !entity?.columns.some(
    (c) => columnWithDynamicValue(entities, entity, c, props?.[c.name]).length,
  );

export const checkFormValidation = (formValidation: FormValidation) =>
  !formValidation ||
  !Object.values(formValidation?.fields ?? {}).some((f) => f.isValid === false);

const getRequiredColumns = (
  cols: EntityColumn[],
  groupColumns: GroupColumn[] = [],
): GroupColumn[] => {
  return groupColumns.filter((column) => {
    const col = findColumn(cols, column.columnName);
    return col && (column.required || col.required) && !col.readOnly;
  });
};

export const validateGroupColumns = (
  cols: EntityColumn[],
  props: Properties,
  groupColumns: GroupColumn[],
) => {
  const requiredColumns = getRequiredColumns(cols, groupColumns);

  return R.all((column) => !R.isNil(props[column.columnName]), requiredColumns);
};

const validateLayout = (
  cols: EntityColumn[],
  props: Properties,
  layout: Layout,
) =>
  !layout ||
  R.all(
    (section) => validateGroupColumns(cols ?? [], props, section.columns),
    layout.groups || [],
  );

export const hasAnyFieldFilledIn = (form: Properties) =>
  R.toPairs(form ?? defaultFor()).some(
    ([column, value]) => !COLUMNS_TO_SKIP.includes(column) && !R.isNil(value),
  );

const hasNoSelfReferencingColumnValues = (entity: Entity, props: Properties) =>
  !behaveAs("Tree", entity) ||
  !entity?.columns?.some((c) => {
    const value = props[c.name];
    return (
      entity.arguments.fkColumn === c.name &&
      isRecordSelfReference(entity.name, props?.id, c, value)
    );
  });

export const isBehaviorFormValid = (
  entity: Entity,
  props: Properties = defaultFor(),
) =>
  !behaveAs("ConditionMonitoring", entity) ||
  isConditionMonitoringFormValid(props);

// this ignores the check for hasAnyFieldFilledIn
export const isFormWithDynamicValuesValid = (
  entities: Entities,
  entity: Entity,
  layout: Layout,
  formValidation?: FormValidation,
  props: Properties = defaultFor(),
) =>
  areColumnWithDynamicValuesValid(entities, props, entity) &&
  checkFormValidation(formValidation) &&
  validateLayout(entity?.columns, props, layout) &&
  isBehaviorFormValid(entity, props);

export const isFormValid = (
  entity: Entity,
  layout: Layout,
  formValidation?: FormValidation,
  props: Properties = defaultFor(),
) =>
  areColumnValuesValid(props, entity) &&
  checkFormValidation(formValidation) &&
  validateLayout(entity?.columns, props, layout) &&
  hasAnyFieldFilledIn(props) &&
  isBehaviorFormValid(entity, props) &&
  hasNoSelfReferencingColumnValues(entity, props);

export const omitEmptyFields = (form: Properties) => {
  return R.pickBy((val) => !R.isNil(val), form);
};

const getRequiredColumnsWithoutValue = (
  cols: EntityColumn[],
  props: Properties,
  groupColumns: GroupColumn[],
): GroupColumn[] => {
  const requiredColumns = getRequiredColumns(cols, groupColumns);

  return requiredColumns.filter((column) => R.isNil(props[column.columnName]));
};

const getMissingRequiredFormColumns = (
  entity: Entity,
  layout: Layout,
  props: Properties,
) => {
  const missingColumns = getRequiredColumnsWithoutValue(
    entity.columns,
    props,
    getLayoutGroupColumns(layout?.groups),
  );

  return missingColumns.map(
    (groupColumn) => getColumn(entity, groupColumn.columnName)?.localizedName,
  );
};

const prepareColumnNames = (columns: string[]) =>
  R.uniq(columns).sort((a, b) => a.localeCompare(b));

const validateColumnValues = (
  entity: Entity,
  layout: Layout,
  columnValuesValidation: ColumnValuesValidation,
  props: Properties,
) => {
  const result: ColumnValuesValidation = {
    ...columnValuesValidation,
    required: getMissingRequiredFormColumns(entity, layout, props) ?? [],
  };

  entity?.columns?.forEach((col) => {
    if (skipColumnValidation(col, entity)) return;

    const columnValidation = column(col, props[col.name]);

    columnValidation.forEach((validationType) => {
      result[validationType].push(col.localizedName);
    });
  });

  Object.keys(result).forEach((validationType) => {
    result[validationType] = prepareColumnNames(result[validationType]);
  });

  return result;
};

const getInvalidFields = (entity: Entity, formValidation: FormValidation) => {
  const invalidFields: string[] = [];

  Object.keys(formValidation?.fields ?? {}).forEach((fieldName) => {
    const field = formValidation.fields[fieldName];

    if (!field?.isValidating && !field?.isValid) {
      invalidFields.push(getColumn(entity, fieldName)?.localizedName);
    }
  });

  return invalidFields;
};

export const getValidationResults = (
  entity: Entity,
  layout: Layout,
  props: Properties,
  formValidation?: FormValidation,
): ValidationResults => {
  const defaultColumnValuesValidation: ColumnValuesValidation = {
    required: [],
    maxValue: [],
    minValue: [],
    maxLength: [],
    minLength: [],
    invalidDecimal: [],
    invalid: [],
    unique: [],
  };

  return entity && props
    ? {
        // Get the validation results for each column
        columnValuesValidation: validateColumnValues(
          entity,
          layout,
          defaultColumnValuesValidation,
          props,
        ),
        // Get the invalid fields from Form validation
        formValidationInvalidFields: getInvalidFields(entity, formValidation),
      }
    : {
        columnValuesValidation: defaultColumnValuesValidation,
        formValidationInvalidFields: [],
      };
};

const getValidationMessageForType = (
  validationType: string,
  fields: string,
) => {
  switch (validationType) {
    case "required":
      return _(
        "The following required fields are missing input: {REQUIRED_FIELDS}",
      ).replace("{REQUIRED_FIELDS}", fields);

    case "maxValue":
      return _(
        "The following fields exceeded the maximum value: {FIELDS}",
      ).replace("{FIELDS}", fields);

    case "minValue":
      return _(
        "The following fields do not meet the minimum value: {FIELDS}",
      ).replace("{FIELDS}", fields);

    case "maxLength":
      return _(
        "The following fields exceeded the maximum length: {FIELDS}",
      ).replace("{FIELDS}", fields);

    case "minLength":
      return _(
        "The following fields do not meet the minimum length: {FIELDS}",
      ).replace("{FIELDS}", fields);

    case "invalidDecimal":
      return _(
        "The following fields have an invalid decimal value: {FIELDS}",
      ).replace("{FIELDS}", fields);

    case "unique":
      return _(
        "The following fields have a value that is not unique: {FIELDS}",
      ).replace("{FIELDS}", fields);

    case "invalid":
      return _("The following fields have an invalid value: {FIELDS}").replace(
        "{FIELDS}",
        fields,
      );

    default:
      return "";
  }
};

export const getValidationWarningMessages = (
  validationResults: ValidationResults,
) => {
  const warningMessages: string[] = [];

  const { required: missingRequired, ...columnValidation } =
    validationResults.columnValuesValidation;
  const { formValidationInvalidFields } = validationResults;

  if (missingRequired.length > 0) {
    warningMessages.push(
      _(
        "The following required fields are missing input: {REQUIRED_FIELDS}",
      ).replace("{REQUIRED_FIELDS}", missingRequired.join(", ")),
    );
  }

  Object.entries(columnValidation).forEach(([validationType, columnNames]) => {
    if (columnNames.length === 0) return;

    warningMessages.push(
      getValidationMessageForType(validationType, columnNames.join(", ")),
    );
  });

  if (formValidationInvalidFields.length > 0) {
    warningMessages.push(
      _("The following fields have invalid value: {FIELDS}").replace(
        "{FIELDS}",
        formValidationInvalidFields.join(", "),
      ),
    );
  }

  return warningMessages;
};

export const isFormDirty = (
  defaultForm: Properties,
  defaultsInLayout: Properties,
  form: Properties,
  ignoredProperties: KeysOf<Properties> = [],
) => {
  const formDefaults = {
    ...defaultForm,
    ...defaultsInLayout,
  };
  const cleanForm = R.omit(ignoredProperties, form);
  const formWithFilledFields = omitEmptyFields(cleanForm);

  return R.isEmpty(formDefaults)
    ? hasAnyFieldFilledIn(formWithFilledFields)
    : !deepEqual(formDefaults, formWithFilledFields);
};

export const setColumnIsValid = (
  formValidation: FormValidation,
  columnName: string,
  isValid: boolean,
) => merge3("fields", columnName, "isValid", isValid, formValidation);

export const setColumnIsValidating = (
  formValidation: FormValidation,
  columnName: string,
  isValidating: boolean,
) => merge3("fields", columnName, "isValidating", isValidating, formValidation);
