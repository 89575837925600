import { Culture } from "common/culture/supported-cultures";
import { ForeignKey, SubForeignKey } from "common/types/foreign-key";
import { Geolocation } from "common/types/geolocation";
import { ESignatureReason, Reason } from "common/types/reasons";

export interface RelatedRecords {
  [entityName: string]: Record[];
}

export interface Properties {
  site?: string;
  sites?: string[];
  /**
   * ID generated in the Front-End for a new record before saving it in DB.
   * This ID is passed to Back-End and used to create a record. It is used
   * for ex. to attach documents before saving a record.
   */
  $id?: string;
  /**
   * ID of a record already saved in DB
   */
  id?: string;
  number?: number;
  isDeleted?: boolean;
  formId?: number;
  image?: string;
  geolocation?: Geolocation;
  reason?: Reason;
  assetId?: ForeignKey;
  order?: number;
  status?: string;
  relatedSummary?: RelatedSummary[];
  taxValue?: any;
  total?: any;
  liveAssetUrl?: string;

  [name: string]: any;
}

export type KeysOf<T> = Array<Extract<keyof T, string>>;

export type PropertyValue =
  | string
  | number
  | boolean
  | Geolocation
  | Reason
  | ForeignKey
  | Properties
  | RelatedSummary[];

export interface Record {
  properties: Properties;
  actions: string[];
  related?: RelatedRecords;
  deleted?: boolean;
  isNew?: boolean;
  reason?: ESignatureReason;
  byPassCheckExplicitAuthentication?: boolean;
}

export interface RelatedPayload {
  [entityName: string]: RecordPayload[];
}

export interface RelatedSummary {
  name: string;
  count?: number;
  items?: SummaryRecord[];
}

export type SummaryRecordTitle = SubForeignKey<string> | string | number;

export interface SummaryRecord {
  number: number;
  id: string;
  title: SummaryRecordTitle;
  subtitle?: SummaryRecordTitle;
  subsubtitle?: SummaryRecordTitle;
}

export interface RecordPayload {
  properties: Properties;
  related?: RelatedPayload;
  deleted?: boolean;
  isNew?: boolean;
  reason?: ESignatureReason;
  byPassCheckExplicitAuthentication?: boolean;
}

export interface Adjustment {
  onHand: number;
  adjustmentType: string;
  batchId: string;
  date: string;
  cost: number | string;
  comment: string;
}

export interface Transfer {
  quantity: number;
  toStockId: string;
  date: string;
  comment: string;
  fromBatchId?: string;
}

export interface EmailBase {
  subject?: string;
  body?: string;
  emailTemplateId?: number;
  culture?: Culture;
  isRecordPdfAttached?: boolean;
  includeAuditTrail?: boolean;
}

export interface Email extends EmailBase {
  to: string;
  cc?: string;
}

export interface SiteRecordIds {
  site: string;
  ids: string[];
}

export interface FkEventValues {
  [index: string]: string;
}

export const defaultRecord: Record = {
  properties: {},
  actions: [],
};
