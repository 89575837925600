import { Geolocation } from "common/types/geolocation";
import { withValue, WithValue } from "common/with-value";
import { PropTypes } from "common/vendor-wrappers/geolocation/types";
import { GeolocationWrapper } from "common/vendor-wrappers/geolocation";

import "./index.scss";

export const GeolocationInput = withValue<Geolocation, PropTypes>(
  ({
    readonly,
    value,
    onChangeSetValue,
  }: PropTypes & WithValue<Geolocation>) => {
    return (
      <div className="x-geolocation">
        <GeolocationWrapper
          readonly={readonly}
          value={value}
          onChange={onChangeSetValue}
        />
      </div>
    );
  },
  "GeolocationInput",
);
