import { findRoot, getSubTree } from "common/utils/tree";
import { NodeComponent } from "./node";
import { TreePropTypes as PropTypes } from "./types";

import "./index.scss";

export const Tree = ({
  nodes,
  selected,
  isDisabled,
  textToHighlight,
  onDisplay,
  onClick,
  onAdd,
}: PropTypes) => {
  const root = findRoot(nodes);
  const nodesWithoutCycles = getSubTree(nodes, root);

  return (
    <div className="x-tree">
      <NodeComponent
        nodes={nodesWithoutCycles}
        node={root}
        selected={selected}
        textToHighlight={textToHighlight}
        isDisabled={isDisabled}
        onDisplay={onDisplay}
        onAdd={onAdd}
        onClick={onClick}
      />
    </div>
  );
};

Tree.displayName = "Tree";
