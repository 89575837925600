import { createRef, ChangeEvent } from "react";
import { withDebounce } from "common/with-debounce";
import { ValueComponent } from "common/with-value-for";

import "./index.scss";

interface PropTypes {
  onClear?: () => void;
  focus?: boolean;
}

export class InputWithSearch extends ValueComponent<string, PropTypes> {
  static readonly displayName = "InputWithSearch";
  inputRef = createRef<HTMLInputElement>();

  focusInput = () => this.inputRef.current.focus();

  componentDidMount() {
    if (this.props.focus) {
      this.focusInput();
    }
  }

  onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e.target.value);
  };

  onClearClick = () => {
    const { onClear, onChange } = this.props;
    onClear ? onClear() : onChange(undefined);
    this.focusInput();
  };

  render() {
    const { value = "" } = this.props;
    return (
      <div className="x-search-input">
        <input
          ref={this.inputRef}
          placeholder={_("Search")}
          value={value}
          onChange={this.onSearchChange}
        />
        {value.length ? (
          <i
            className="fa fa-close"
            title={_("Clear value")}
            onClick={this.onClearClick}
          />
        ) : (
          <i className="fa fa-search" />
        )}
      </div>
    );
  }
}

export const DebounceInputWithSearch = withDebounce(InputWithSearch);
