import { getBorderColor, stringifyRgb } from "common/functions/colors";
import { ActionButtonSmall } from "common/ui/buttons";
import { ColorLegendGroup, ColorLegendItem } from "x/records/list/functions";
import { ButtonModal } from "../modal/button-modal";

import "./index.scss";

interface PropTypes {
  colorLegendGroups: ColorLegendGroup[];
}

// TODO: create components in place of below fns to return jsx
export const ColorLegendButton = ({ colorLegendGroups }: PropTypes) => {
  const getColorLegendItemMarkup = (item: ColorLegendItem) => {
    if (!item) return undefined;

    const { label, color } = item;
    const style = {
      backgroundColor: stringifyRgb(color),
      borderColor: stringifyRgb(getBorderColor(color)),
    };

    return (
      <div
        key={item.label}
        className="x-color-legend-item qa-color-legend-item"
      >
        <div
          className="x-color-legend-item-color qa-color-legend-item-color"
          style={style}
        />
        <div className="x-color-legend-item-label qa-color-legend-item-label">
          {label}
        </div>
      </div>
    );
  };

  const getColorLegendGroupMarkup = (
    group: ColorLegendGroup,
    index: number,
  ) => {
    if (!group) return undefined;

    const { label, items = [] } = group;

    return (
      <div
        key={label ?? index}
        className="x-color-legend-group qa-color-legend-group"
      >
        {label ? (
          <legend className="x-label qa-color-legend-group-label">
            {label}
          </legend>
        ) : null}
        {items.map(getColorLegendItemMarkup)}
      </div>
    );
  };

  return (
    <ButtonModal
      button={
        <ActionButtonSmall className="x-color-legend-button qa-color-legend-button">
          <span className="x-color-legend-label-full qa-color-legend-label-full">
            {_("Color Legend")}
          </span>
          <span className="x-color-legend-label-short qa-color-legend-label-short">
            {_("Legend")}
          </span>
        </ActionButtonSmall>
      }
      content={(colorLegendGroups || []).map(getColorLegendGroupMarkup)}
      size={"medium"}
      title={_("Color Coding Legend")}
      allowFullscreen={false}
      hideFooter={true}
    />
  );
};

ColorLegendButton.displayName = "ColorLegendButton";
