import * as R from "ramda";
import { deepEqual } from "common/component";
import { getStartOf } from "common/date-time/calculators";
import { isDateOperator, isDateTimeOperator } from "common/entities/operators";
import { Entities, Entity } from "common/entities/types";
import { getFkId } from "common/functions/foreign-key";
import { merge2, mergeChain } from "common/merge";
import { shouldExcludeFromFkExpansion } from "common/query/expansion";
import { getPathMap } from "common/query/joins";
import {
  Filter,
  FilterType,
  isAnd,
  isOr,
  isRule,
  QueryForEntity,
  Query,
  FilterRule,
  SelectField,
  isSubQuery,
  isExpressionRule,
  FilterSubQueryRule,
} from "common/query/types";
import { similarArray } from "common/utils/array";

// TODO this function is not handling edge cases
// filter and query.filter can be 3 different formats each
export const addFilterToQuery = (
  filter: Filter,
  query: Query,
  isOr?: boolean,
): Query => {
  const filterKey = isOr ? "or" : "and";
  const queryFilter = query?.filter;
  return R.assocPath(
    ["filter"],
    {
      [filterKey]: queryFilter ? [filter, queryFilter] : [filter],
    },
    query,
  );
};

export const addFilter = (
  filter: Filter,
  queryForEntity: QueryForEntity,
  isOr?: boolean,
): QueryForEntity => ({
  ...queryForEntity,
  query: addFilterToQuery(filter, queryForEntity && queryForEntity.query, isOr),
});

export const similarFilter = (a: Filter, b: Filter): boolean => {
  if (a === b) return true;
  if (isRule(a) && isRule(b)) return deepEqual(a, b);
  if (isAnd(a) && isAnd(b) && a.and.length === b.and.length)
    return similarArray(a.and, b.and);
  if (isOr(a) && isOr(b) && a.or.length === b.or.length)
    return similarArray(a.or, b.or);
  return false;
};

export const addIdFilter = (
  query: QueryForEntity,
  entityName: string,
  entities: Entities,
  recordId?: string | number,
): QueryForEntity => {
  const pathToEntity = R.toPairs<Entity>(getPathMap(entities, query))
    .filter((e) => e[1] && e[1].name === entityName)
    .map((e) => e[0]);

  return pathToEntity.reduce(
    (acc, path) =>
      addFilter(
        {
          path: path || null,
          name: "id",
          op: "eq",
          value: recordId || "{id}",
        },
        acc,
        pathToEntity.length > 1,
      ),
    query,
  );
};

export const addIsDeletedFilterToQuery = (
  query: Query,
  isDeletedValue: boolean,
) =>
  addFilterToQuery(
    { name: "isDeleted", op: isDeletedValue ? "istrue" : "isfalse" },
    query,
  );

export const addIsDeletedFilter = (
  queryForEntity: QueryForEntity,
  isDeletedValue: boolean,
): QueryForEntity => ({
  ...queryForEntity,
  query: addIsDeletedFilterToQuery(queryForEntity.query, isDeletedValue),
});

const flattenFilterById = (filter: Filter): Filter => {
  if (!filter) return undefined;

  if (isSubQuery(filter)) return filter;
  if (isRule(filter)) {
    const { value, op } = filter;
    return shouldExcludeFromFkExpansion(value)
      ? mergeChain(filter)
          .setMany({
            value: getFkId(value),
            op: getFkId(value) ? op : "isnull",
            excludeFromFkExpansion: true,
          })
          .output()
      : filter;
  }

  const oldFilter = isAnd(filter) ? filter.and : filter.or;
  const newFilter = (oldFilter || []).map(flattenFilterById);

  return R.mergeRight(filter, { [isAnd(filter) ? "and" : "or"]: newFilter });
};

export const flattenFkValues = (query: QueryForEntity): QueryForEntity => {
  const filter = query?.query?.filter;
  return merge2("query", "filter", flattenFilterById(filter), query);
};

export const getFlatRulesMapper = <T>(fn: (rule: FilterRule) => T) => {
  return function mapFilter(f: Filter): T[] {
    if (isAnd(f)) {
      return f.and.map(mapFilter).flat();
    }

    if (isOr(f)) {
      return f.or.map(mapFilter).flat();
    }

    if (isSubQuery(f)) {
      const filter = f.queryValue?.filter;
      if (!filter) return [];
      if (isAnd(filter)) {
        return filter.and.map(mapFilter).flat();
      }
      if (isOr(filter)) {
        return filter.or.map(mapFilter).flat();
      }
      return [];
    }

    return [fn(f)];
  };
};

export const getRulesMapper = (fn: (rule: FilterRule) => FilterRule) => {
  return function mapFilter(f: Filter): Filter {
    if (isAnd(f)) {
      return { and: f.and.map(mapFilter) };
    }

    if (isOr(f)) {
      return { or: f.or.map(mapFilter) };
    }

    if (isSubQuery(f)) return f;

    return fn(f);
  };
};

export const getRulesValidator = (
  fn: (rule: FilterRule | FilterSubQueryRule) => boolean,
) => {
  return function isValid(filter: Filter): boolean {
    if (isAnd(filter)) {
      return filter.and.every(isValid);
    }
    if (isOr(filter)) {
      return filter.or.every(isValid);
    }
    return fn(filter);
  };
};

export const isEmptyFilter = (filter: Filter) =>
  !filter ||
  (!isRule(filter) &&
    !(isAnd(filter) && filter.and.length > 0) &&
    !(isOr(filter) && filter.or.length > 0));

const allFilterRules = (filters: Filter[] = []): boolean =>
  R.all(isRule, filters);

export const getFilterByType = (filter: Filter, type: FilterType): Filter[] => {
  if (!filter || isRule(filter) || isSubQuery(filter)) return [];
  if (isAnd(filter) && type === "and" && allFilterRules(filter.and)) {
    return filter.and;
  }
  if (isOr(filter) && type === "or" && allFilterRules(filter.or)) {
    return filter.or;
  }

  const filters = isAnd(filter) ? filter.and : filter.or;

  return R.flatten(filters.map((f) => getFilterByType(f, type))).filter(
    (f) => f,
  );
};

export const getFilterKey = (query: Query): string => {
  if (!query) return "";
  const { filter } = query;

  const ors = getFilterByType(filter, "or");

  const firstFilter = ors[0];
  return firstFilter && isRule(firstFilter) ? firstFilter.value : "";
};

export const setFilterExcludingArchived = (query: Query, filter: Filter) =>
  filter ? addIsDeletedFilterToQuery({ ...query, filter }, false) : query;

export const modifyPathInFilter = (
  filter: Filter,
  select: SelectField[],
  path: string,
): Filter => {
  if (!path) return filter;

  const replaceFilter = (f: Filter) => modifyPathInFilter(f, select, path);

  if (isAnd(filter)) return { and: filter.and.map(replaceFilter) };
  if (isOr(filter)) return { or: filter.or.map(replaceFilter) };
  if (isSubQuery(filter)) return filter;
  if (isExpressionRule(filter)) return filter;

  return R.mergeRight(filter, {
    path: select.some((c) => c.name === filter?.name) ? `/${path}` : undefined,
  }) as FilterRule;
};

export const getAdjustedDateTimeFilterValue = (
  filter: FilterRule,
  op: string,
) =>
  filter &&
  (isDateTimeOperator(filter.op) && isDateOperator(op)
    ? getStartOf(filter.value, "day")
    : filter.value);
